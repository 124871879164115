[data-controller="multiple-select"] {
  display: none;
}

.ts-wrapper {
  position: relative;
}

.ts-wrapper .dropdown-input-wrap {
  @apply p-2;
}

.ts-wrapper .dropdown-input-wrap input,
.ts-wrapper .ts-control input {
  @apply border mb-2 p-2 rounded-md w-full;
}

.ts-wrapper .ts-dropdown .ts-dropdown-content {
  @apply absolute left-0 z-1 overflow-y-scroll max-h-40 w-full bg-white border border-stone-300 border-t-0 rounded-b-md;
}

.ts-wrapper .ts-dropdown .active {
  @apply outline-none ring ring-indigo-500 bg-indigo-100;
}
