.sidebar-item {
  @apply block pl-2 py-2 mt-2 text-sm text-stone-900 rounded-lg hover:text-stone-900 focus:text-stone-900 hover:bg-stone-200 focus:bg-stone-200 focus:outline-none;
}

.sidebar-item-active i {
  @apply text-sky-800;
}

.pipelines .sidebar-item.active {
  @apply text-stone-900 bg-stone-200;
}

.sidebar-pipelines-container .active-icon,
.sidebar-pipelines-container .toggle-class-active .inactive-icon {
  display: none;
}
.sidebar-pipelines-container .inactive-icon,
.sidebar-pipelines-container .toggle-class-active .active-icon {
  display: inline-flex;
}

.sidebar-pipelines-container .filter-item.active {
  @apply bg-stone-200/70 rounded-r-lg -ml-2 pl-2;
}
