/* animations */

@keyframes wiggle {
  0%,
  100% {
    transform                : rotate(-1deg);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform                : rotate(1deg);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@keyframes hide {
  0% {
    opacity: 100;
  }

  99% {
    opacity: 0;
  }

 100% {
  opacity: 0;
 }
}

.animate-hide {
  animation: hide 0.5s forwards;
  animation-delay: 1s;
}

.animate-wiggle {
  animation: wiggle 1s infinite;
}

.animate-wiggle-3x {
  animation: wiggle 1s 3;
}
