.opportunity-card .opportunity-error-alert {
  display: none;
}

.opportunity-card .opportunity-saving {
  display: none;
}

.opportunity-card.opportunity-moving .opportunity-saving {
  display: block;
}

.opportunity-card.opportunity-moving {
  @apply opacity-50;
}

.opportunity-move-has-error {
  @apply border-red-500;
}

.opportunity-move-has-error .opportunity-error-alert {
  display: block;
}


/* TODO: move to step container */
.step-container[data-present='false'] {
  @apply rounded-md border-2 border-dashed border-gray-300;

  min-height: 12rem;
}

/* custom hide handler without changing the html */
.step-container[data-state] .handle {
  display: none;
}

.close-step-container {
  @apply from-gray-50 border border-gray-200 !important;
}

.won-step-container {
  @apply from-green-50 border border-green-200 !important;
}

.lost-step-container {
  @apply from-red-50 border border-red-200 !important;
}
