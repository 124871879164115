.event-item {
  @apply relative p-3 border-l-2 text-sm;
}

.event-item:last-child {
  padding-bottom: 0;
}

.event-item::after {
  content          : "";
  border          : solid 2px #e5e7eb;
  width           : 16px;
  height          : 16px;
  display         : block;
  border-radius   : 100%;
  background-color: #f5f5f4;
  position        : absolute;
  left            : -9px;
  top             : 15px;
}
