.nav-tabs {
  @apply border-b-2 border-stone-300 flex relative;
}

.nav-item {
  @apply border-b-2 px-2 pb-4 text-stone-600 border-stone-300 hover:text-sky-600 hover:border-sky-600 relative;

  bottom: -2px;
}

.nav-item.active {
  @apply text-sky-600 border-sky-600;
}
