@import "./animations";

a {
  @apply text-sky-700;
}

.z-1 {
  z-index: 1;
}

@screen md {
  .w-full-container {
    width: calc(100% - 16rem);
  }
}

.flex-1-auto {
  flex: 1;
}

.hide-scroll {
  scrollbar-width   : none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

.hide-scroll::-webkit-scrollbar {
  width: 0px;
  /* For Chrome, Safari, and Opera */
}

.min-w-96 {
  min-width: 24rem;
}

.min-w-80 {
  min-width: 20rem;
}

/* Align icons */
.ph {
  vertical-align: middle;
  line-height   : 1rem;
}

.ph-1-5x {
  font-size: 1.5rem;
}

.turbo-progress-bar {
  height          : 5px;
  background-color: green;
}
