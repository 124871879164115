.form-control {
  @apply mt-1 p-2 border focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md;
}

.form-select-component {
  min-height: 38px;
}

.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat  : no-repeat;
  background-size    : 1.5em 1.5em;

  @apply mt-1 appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-sky-600 focus:outline-none;

  padding-right: 40px;
}

.form-select:hover {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 12L10 8L6 12' stroke='%236B7280' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.error-message {
  @apply mt-2 text-red-500 text-xs italic
}

.pipeline-dropzone-container .pipeline-dropzone-placeholder {
  display: none;
}

.pipeline-dropzone-container .pipeline-dropzone-placeholder:only-child {
  display: block;
}

.steps-containers.toggle-class-el-active .step-fields-list {
  flex-direction: row;
  display: flex;
}

/* space-x-4 */
.steps-containers.toggle-class-el-active .step-fields-list > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
