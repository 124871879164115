@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./components";
@import"./utils";

.step-item:last-child .step-icon {
  @apply hidden;
}

form[aria-busy="true"] {
  position: relative;
}

form[aria-busy="true"] [loader] {
  display: block !important;
  position: absolute;
  left    : 0;
  top     : 0;
  right   : 0;
  bottom  : 0;
}

form[aria-busy="true"]::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.3);
}

[data-turbo-feedback-target="loader"].hidden {
  display: none;
}
