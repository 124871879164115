/* toggle component */
.toggle-path {
  transition: background 0.3s ease-in-out;
}

.toggle-circle {
  top       : 0.2rem;
  left      : 0.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle-circle {
  transform: translateX(100%);
}

input:checked ~ .toggle-path {
  @apply bg-indigo-500
}
