/* custom-radio component */
.custom-radio-field label {
  @apply p-2 cursor-pointer flex text-lg bg-white
}

/* custom-radio temperature */
.custom-radio-field input:checked~label[data-temperature="cold"] {
  @apply bg-indigo-100 text-indigo-800 text-indigo-800;
}

.custom-radio-field input:checked~label[data-temperature="warm"] {
  @apply bg-yellow-100 text-yellow-500 text-yellow-500;
}

.custom-radio-field input:checked~label[data-temperature="hot"] {
  @apply bg-orange-100 text-orange-500 text-orange-500;
}

.custom-radio-field input:checked~label[data-temperature=""] {
  @apply bg-gray-100 text-gray-500 text-gray-500;
}

/* custom-radio state */
.custom-radio-field input:checked~label[data-state="open"] {
  @apply bg-sky-100 text-sky-500 text-sky-500;
}

.custom-radio-field input:checked~label[data-state="close"] {
  @apply bg-gray-200 text-gray-500 text-gray-500;
}

.custom-radio-field input:checked~label[data-state="won"] {
  @apply bg-emerald-100 text-emerald-500 text-emerald-500;
}

.custom-radio-field input:checked~label[data-state="lost"] {
  @apply bg-red-100 text-red-500 text-red-500;
}

.custom-radio-field input:checked~label[data-state=""] {
  @apply bg-gray-100 text-gray-500 text-gray-500;
}
